<template>
  <div>
    <DataTable
      v-bind="{
        list: objectsPage,
        pending,
        error,
        totalItems,
        pageSize,
        filters,
        header,
      }"
      @selected:filter="onFilterSelect"
    >
      <template #header:status="{ item: label }">
        <th class="text-center">{{ label }}</th>
      </template>

      <template #header:id="{ item: label }">
        <th class="text-center">{{ label }}</th>
      </template>

      <template #header:actions="{ item: label }">
        <th class="text-center xl:pr-11">{{ label }}</th>
      </template>

      <template #col:name="{ item: object }">
        <td class="font-bold pl-11">
          <InfoPopup class="ml-auto" :info="object.name" variant="text" />
        </td>
      </template>

      <template #col:id="{ item: object }">
        <td class="text-center">
          {{ object.isBuyerAsset ? object.referencedAssetId : object.id }}
        </td>
      </template>

      <template #col:organisation="{ item: object }">
        <td>{{ object.ownerCompany }}</td>
      </template>

      <template #col:createdAt="{ item: object }">
        <td>{{ formatDate(object.createdAt) }}</td>
      </template>

      <template #col:createdBy="{ item: { createdBy } }">
        <td>{{ createdBy?.firstName }} {{ createdBy?.lastName }}</td>
      </template>

      <template #col:status="{ item: object }">
        <td :class="`text-${statusColor(object.state)}`">
          <!-- hidden element to trigger tailwind to generate these classes -->
          <i class="text-oceanGreen bg-oceanGreen hidden" />
          <div class="flex items-center w-[122px] mx-auto">
            <span
              class="flex-shrink-0 rounded-full h-5 w-5 xl:h-6 xl:w-6"
              :class="`bg-${statusColor(object.state)}`"
            />
            <span class="font-semibold ml-3">{{
              statusText(object.state)
            }}</span>
          </div>
        </td>
      </template>

      <template #col:actions="{ item: object }">
        <td class="text-center whitespace-nowrap xl:pr-11">
          <Button
            variant="secondary"
            class="px-10"
            :disabled="object.state === null"
            @click="openExportDialog(object.id)"
            >{{ $t('pages.export') }}</Button
          >
        </td>
      </template>
    </DataTable>
    <ExportObjectsOrgDialog
      v-model="openExportObjectsOrg"
      v-bind="{ assetId }"
    ></ExportObjectsOrgDialog>
  </div>
</template>

<script>
import { sortBy } from 'lodash-es'
import { useStore } from 'vuex'
import { EAssetState } from '@/enums'
import { general } from '@/helpers'
import { useListFetch } from '@/composition'
import { DataTable } from '@/components/dataTable'
import InfoPopup from '@/components/form/InfoPopup.vue'
import Button from '@/components/form/button/Button.vue'
import { ExportObjectsOrgDialog } from '@/components/dialog'

export default {
  components: {
    DataTable,
    InfoPopup,
    Button,
    ExportObjectsOrgDialog,
  },
  setup() {
    const store = useStore()
    const errorMessage = () =>
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'objects-list-fetch-error',
      })
    return useListFetch('objects', errorMessage)
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSlice: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: ['update:table'],
  data() {
    return {
      activeFilter: 'all',
      openExportObjectsOrg: false,
      assetId: null,
    }
  },
  computed: {
    openObjects() {
      return this.list.filter(o => o.state === null)
    },
    activeObjects() {
      return this.list.filter(o => o.state === EAssetState.Active)
    },
    reviewableObjects() {
      return this.list.filter(o => o.state === EAssetState.Review)
    },
    evaluatableObjects() {
      return this.list.filter(o => o.state === EAssetState.Evaluation)
    },
    closedObjects() {
      return this.list.filter(o => o.state === EAssetState.Closed)
    },
    declinedObjects() {
      return this.list.filter(o => o.state === EAssetState.Declined)
    },
    verifiableObjects() {
      return this.list.filter(o => o.state === EAssetState.Verification)
    },
    definitelyDeclinedObjects() {
      return this.list.filter(o => o.state === EAssetState.DefinitelyDeclined)
    },
    filteredObjects() {
      switch (this.activeFilter) {
        case 'open':
          return this.openObjects
        case 'active':
          return this.activeObjects
        case 'review':
          return this.reviewableObjects
        case 'evaluation':
          return this.evaluatableObjects
        case 'closed':
          return this.closedObjects
        case 'declined':
          return this.declinedObjects
        case 'verification':
          return this.verifiableObjects
        case 'definitelyDeclined':
          return this.definitelyDeclinedObjects
        default:
          return this.list
      }
    },
    sortedObjects() {
      return sortBy(this.filteredObjects, o => new Date(o.createdBy)).reverse()
    },
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedFilteredObjects() {
      const searchKeys = [
        'id',
        'referencedAssetId',
        'name',
        'ownerCompany',
        'createdBy.firstName',
        'createdBy.lastName',
      ]
      return general.searchOverKeys(
        this.sortedObjects,
        searchKeys,
        this.searchTerm
      )
    },
    objectsPage() {
      return this.searchedFilteredObjects.slice(...this.pageSlice)
    },
    filters() {
      return [
        {
          key: 'all',
          item: {
            label: this.$t('assetManagement.all'),
            active: this.activeFilter === 'all',
            number: this.list.length,
          },
        },
        {
          key: 'open',
          item: {
            label: this.$t('assetManagement.open'),
            active: this.activeFilter === 'open',
            number: this.openObjects.length,
          },
        },
        {
          key: 'active',
          item: {
            label: this.$t('assetManagement.active'),
            active: this.activeFilter === 'active',
            number: this.activeObjects.length,
          },
        },
        {
          key: 'review',
          item: {
            label: this.$t('assetManagement.review'),
            active: this.activeFilter === 'review',
            number: this.reviewableObjects.length,
          },
        },
        {
          key: 'evaluation',
          item: {
            label: this.$t('assetManagement.evaluation'),
            active: this.activeFilter === 'evaluation',
            number: this.evaluatableObjects.length,
          },
        },
        {
          key: 'closed',
          item: {
            label: this.$t('assetManagement.closed'),
            active: this.activeFilter === 'closed',
            number: this.closedObjects.length,
          },
        },
        {
          key: 'declined',
          item: {
            label: this.$t('assetManagement.declined'),
            active: this.activeFilter === 'declined',
            number: this.declinedObjects.length,
          },
        },
        {
          key: 'verification',
          item: {
            label: this.$t('assetManagement.verification'),
            active: this.activeFilter === 'verification',
            number: this.verifiableObjects.length,
          },
        },
        {
          key: 'definitelyDeclined',
          item: {
            label: this.$t('assetManagement.definitelyDeclined'),
            active: this.activeFilter === 'definitelyDeclined',
            number: this.definitelyDeclinedObjects.length,
          },
        },
      ]
    },
    header() {
      return [
        'name',
        'id',
        'organisation',
        'createdAt',
        'createdBy',
        'status',
        'actions',
      ].map(key => ({
        key,
        item: this.$t(`assetManagement.objects-${key}`),
      }))
    },
  },
  methods: {
    statusColor(status) {
      switch (status) {
        case EAssetState.Active:
        case EAssetState.Review:
        case EAssetState.Evaluation:
          return 'oceanGreen'
        case EAssetState.Closed:
          return 'primary'
        case EAssetState.Declined:
        case EAssetState.DefinitelyDeclined:
        case EAssetState.Expired:
          return 'error'
        case EAssetState.Verification:
          return 'yellow'
        default:
          // status is null, this means 'to be answered'
          return 'lightGray'
      }
    },
    statusText(status) {
      const prefix = 'assetManagement.objects-status'

      switch (status) {
        case EAssetState.Active:
          return this.$t(`${prefix}-active`)
        case EAssetState.Review:
          return this.$t(`${prefix}-review`)
        case EAssetState.Evaluation:
          return this.$t(`${prefix}-evaluation`)
        case EAssetState.Closed:
          return this.$t(`${prefix}-closed`)
        case EAssetState.Declined:
          return this.$t(`${prefix}-declined`)
        case EAssetState.Verification:
          return this.$t(`${prefix}-verification`)
        case EAssetState.DefinitelyDeclined:
          return this.$t(`${prefix}-definitelyDeclined`)
        case EAssetState.Expired:
          return this.$t(`${prefix}-expired`)
        default:
          // status is null, this means 'to be answered'
          return this.$t(`${prefix}-open`)
      }
    },
    formatDate(dateString) {
      return general.formatDate(dateString)
    },
    onFilterSelect(key) {
      this.activeFilter = key
    },
    openExportDialog(id) {
      this.openExportObjectsOrg = true
      this.assetId = id
    },
  },
  watch: {
    searchedFilteredObjects(newList) {
      this.$emit('update:table', newList.length)
    },
  },
}
</script>
